<template>
  <article
    @mouseenter="changeImage('random')"
    class="in-product position-relative"
    @mouseleave="changeImage('main')"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
    <template>
      <div v-if="product.charged" class="charged-badge">
        شارژ شد
      </div>
      <div v-else-if="product.status == 'out_of_stock'" class="notExist-badge">
        ناموجود
      </div>
    </template>
    <template v-if="product && product.major_final_price">
      <DiscountTag :final-price="product.major_final_price" />
    </template>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
      class="text-center"
    >
      <div v-if="product" class="recommended-products-img text-center">
        <img
          class="lazy"
          v-if="product.varieties"
          :class="{ grayscale: product.status == 'out_of_stock' }"
          ref="imageSrc"
          :src="imageSrc"
          :alt="product.title"
        />
        <img
          class="lazy"
          v-else
          :class="{ grayscale: product.status == 'out_of_stock' }"
          ref="imageSrc"
          :src="mainImage"
          :alt="product.title"
        />
      </div>

      <div v-if="product" class="text-center position-relative ">
        <div
          class="product-rate d-flex align-items-center justify-content-center"
        >
          <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
          <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
        </div>
        <h6
          v-if="product.title"
          class=" weight-bold fontsize-medium mb-0 mt-2 mt-sm-3"
          :class="{
            'text-color-444': !priceColorFlag,
            'price-color-themeWhite': priceColorFlag,
          }"
        >
          {{ product.title ? product.title.substr(0, 33) : "" }}
          {{ product.title.length > 33 ? "..." : "" }}
        </h6>
        <div v-if="product.status == 'available'||product.status == 'out_of_stock'">
          <span
            class=" fontsize-medium"
            :class="{
              'text-color-themeGreen': !priceColorFlag,
              'price-color-themeWhite': priceColorFlag,
            }"
          >
            {{ mainPrice | price }}
          </span>
          <span
            :class="{
              'text-color-666': !priceColorFlag,
              'price-color-themeWhite': priceColorFlag,
            }"
            class=" fontsize-small pr-1"
            >تومان</span
          >
          <span v-if="discountAmount != 0" class="pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
            <span class="line-through text-color-999 fontsize-small">{{
              (mainPrice + discountAmount) | price
            }}</span>
          </span>
        </div>
        <div v-else class="hide-by-hover ">
         
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3 px-4 px-sm-5">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </router-link>
  </article>
</template>
<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";

import { getImages, sortVarieties, getImage } from "@/libraries/functions.js";
import DiscountTag from "./DiscountTag";
import Tick from "@/parts/Front/components/shared/Tick";
export default {
  name: "Product",
  components: { Tick, DiscountTag, BFormRating, BSkeleton },
  props: {
    product: Object,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
    priceColorFlag: {
      default: false,
      type: Boolean,
    },
  },

  mounted() {
    if (this.product) {
      this.fillAllImagesOfProduct();
      this.imageSrc = this.product.images[0] ? this.product.images[0].url : "";
      if (this.product.varieties) {
        this.changeImage("main");
      }
    }
  },
  data() {
    return {
      imageSrc: null,
      allImageOfProduct: null,
      i: -1,
      changeRouter: "changed",
      currentIndex: -1,
    };
  },
  methods: {
    fillAllImagesOfProduct() {
      this.allImageOfProduct = [];
      this.product.varieties.forEach((variety) => {
        if (variety.images.length) {
          if (
            variety.images[0] &&
            this.product.images[0] &&
            variety.images[0].url != this.product.images[0].url
          )
            this.allImageOfProduct.push(variety.images[0]);
        }
        // console.log(this.allImageOfProduct);
      });
    },
    changeImage(which) {
      if (!this.product.varieties) {
        this.imageSrc = this.product.images[0]
          ? this.product.images[0].url
          : "";
        return;
      }
      this.fillAllImagesOfProduct();
      if (which == "main") {
        this.imageSrc = this.mainImage;
        this.$refs.imageSrc.classList.add("fadeImage");
        this.$refs.imageSrc.classList.remove("showImage");
      } else {
        if (this.changeRouter != null) {
          this.changeRouter = null;
        }
        if (this.allImageOfProduct.length == 0) {
          this.imageSrc = this.allImageOfProduct[0].url;
        } else if (this.allImageOfProduct.length > 0) {
          this.currentIndex++;
          this.currentIndex = this.currentIndex % this.allImageOfProduct.length;
          if (this.currentIndex == 0) this.currentIndex++;

          this.imageSrc = this.allImageOfProduct[this.currentIndex].url;
        }
        this.$refs.imageSrc.classList.add("showImage");
        this.$refs.imageSrc.classList.remove("fadeImage");
      }
    },
    getImage,
  },

  computed: {
    mainImage() {
      return this.product
        ? this.product.images.length
          ? this.product.images[0].url
          : this.product.major_image.url
        : null;
    },
    mainPrice() {
      return this.product ? this.product.price : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },

    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
  },
  watch: {
    $route: {
      handler() {
        this.changeRouter = "changed";
      },
    },
    product(newD) {
      if (newD) {
        this.changeImage("main");
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}

.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}

.compare-btn {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}

.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}

.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.recommended-content-item article:hover {
  transform: translateY(0px);
}

.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}

.py-25 {
  padding: 14px 0;
}

.price-theme-color {
  color: #8a149c;
}
/* .newImage {
  animation: newImage 1s linear forwards;
}
@keyframes newImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 
*/

.fadeImage {
  -webkit-animation-name: fadeImage;
  animation-name: fadeImage;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.showImage {
  -webkit-animation-name: showImage;
  animation-name: showImage;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes showImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.charged-badge {
  background-color: blue;
  color: white;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 3px 10px;
  position: absolute;
  right: 0;
  z-index: 99;

}
.notExist-badge {
  background: gray;
  color: white;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 3px 10px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.grayscale{
  filter: grayscale(1);
}
</style>
