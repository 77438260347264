<template>
  <span
    v-if="finalPrice.discount_type == 'percentage'"
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
    >{{ finalPrice.discount }}% تخفیف</span
  >
  <span
    v-else-if="finalPrice.discount_type == 'flat'"
    class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"
  >
    <template v-if="!isMobile"> تخفیف :</template>
    {{ finalPrice.discount | price }} تومان
  </span>
</template>

<script>
export default {
  name: "DiscountTag",
  inject: ["mediaQueries"],
  props: ["finalPrice"],
  computed: {
    isMobile() {
      return this.mediaQueries.mobileSize;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .recommended-discount {
    font-size: 0.7rem;
  }
}
</style>
